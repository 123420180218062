<template>
  <div v-loading="loading" class="content-wrapper">
    <div class="content-box">
      <h2>{{ region.app_name }}</h2>
      <el-steps
        :active="step"
        finish-status="success"
        :align-center="true"
        style="margin-bottom: 32px;"
      >
        <el-step title="Veranstaltungskonto"></el-step>
        <el-step title="Basisinfos"></el-step>
        <el-step title="Kontakt"></el-step>
      </el-steps>
      <account-form
        v-if="step === 0"
        :saving="saving"
        :user="user"
        submit-label="Weiter"
        @form-submitted="createUser"
      />
      <organization-basic-form
        v-if="step === 1"
        :organization="organization"
        @form-submitted="saveOrgBasic"
      ></organization-basic-form>
      <organization-contact-form
        v-if="step === 2"
        :organization="organization"
        :region="region"
        @form-submitted="createOrganization"
        @form-prev="backToOrgBasic"
      ></organization-contact-form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import utils from "@/utils"
import Repository from "@/repositories/api.js"
import AccountForm from "@/components/forms/AccountForm"
import OrganizationBasicForm from "@/components/forms/OrganizationBasicForm"
import OrganizationContactForm from "@/components/forms/OrganizationContactForm"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Registrieren"
  },
  components: {
    AccountForm,
    OrganizationBasicForm,
    OrganizationContactForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      step: 0,
      saving: false,
      loading: true,
      region: {},
      user: {},
      organization:
        JSON.parse(localStorage.getItem("familien-app-temp-org")) || {}
    }
  },
  computed: {
    ...mapGetters("auth", ["authenticated", "isOrganizationUser"])
  },
  async created() {
    if (this.authenticated && !this.isOrganizationUser) {
      this.step = 1
    }

    try {
      let response = await Repository.get(`regions/${this.$route.params.id}`)
      this.region = response.data.region
      this.user.region_id = this.region.id
      this.organization.region_id = this.region.id
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async createUser(user) {
      this.saving = true
      this.user = user
      try {
        let response = await Repository.post("signup", { user: this.user })
        this.$store.commit("auth/login", response.data)
        this.saving = false
        this.step = 1

        utils.addErrorBreadcrumb({
          category: "Auth", // e.g. "UI", "Network", "Navigation", "Console"
          action: "Signed up", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
          metadata: {
            user_type: this.$store.getters["auth/userType"],
            user_id: this.$store.getters["auth/userData"].id,
            user_email: this.$store.getters["auth/userData"].email
          } // key/value metadata in <string, string> format
        })
      } catch (error) {
        this.saving = false
        if (error.response && error.response.status === 422) {
          this.$message({
            showClose: true,
            message: "Diese E-Mail-Adresse ist bereits in Benutzung",
            type: "error",
            duration: 10000
          })
          utils.trackError("SignUpError", "E-Mail bereits in Benutzung")
        } else {
          this.handleApiError(error, "Fehler bei Registrierung")
        }
      }
    },
    saveOrgBasic(organization) {
      this.organization = organization
      localStorage.setItem(
        "familien-app-temp-org",
        JSON.stringify(this.organization)
      )
      this.step = 2
    },
    backToOrgBasic(organization) {
      this.organization = { ...this.organization, ...organization }
      localStorage.setItem(
        "familien-app-temp-org",
        JSON.stringify(this.organization)
      )
      this.step = 1
    },
    async createOrganization(organization) {
      this.organization = { ...this.organization, ...organization }
      this.saving = true

      try {
        let response = await Repository.post("support_organizations", {
          support_organization: this.organization
        })
        this.$store.commit(
          "auth/setSupportOrganization",
          response.data.support_organization.id
        )
        this.saving = false
        localStorage.removeItem("familien-app-temp-org")
        this.$router.push({ name: "EventsPage" })
        this.$message({
          message: `Organisation wurde erfolgreich angelegt.`,
          type: "success",
          showClose: true
        })
        utils.addErrorBreadcrumb({
          category: "Auth", // e.g. "UI", "Network", "Navigation", "Console"
          action: "Created organization for user", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
          metadata: {
            user_type: this.$store.getters["auth/userType"],
            user_id: this.$store.getters["auth/userData"].id,
            user_email: this.$store.getters["auth/userData"].email
          } // key/value metadata in <string, string> format
        })
      } catch (error) {
        this.saving = false
        this.handleApiError(error, "Fehler Erstellung Veranstalter")
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  width: 100vw;
  padding-top: 40px;
  justify-content: center;
}

.content-box {
  width: 600px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

h2 {
  text-align: center;
  margin-bottom: 24px;
}
</style>
