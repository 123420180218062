<template>
  <el-card v-loading="loading" style="margin-bottom: 24px;">
    <template #header>
      {{ heading }}
    </template>
    <apexchart
      :type="type"
      :options="chartOptions"
      :series="series"
      :height="height"
      width="100%"
    ></apexchart>
  </el-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    chartOptions: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    heading: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    height: {
      type: String,
      default: ""
    }
  }
}
</script>
