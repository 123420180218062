<template>
  <div class="wrapper">
    <h2>Anmeldung im Forum…</h2>
    <div v-loading="true"></div>
  </div>
</template>

<script>
import Repository from "@/repositories/api.js"
import utils from "@/utils"

export default {
  metaInfo: {
    title: "Login"
  },

  data() {
    return {}
  },
  computed: {},
  async created() {
    if (!this.$route.query.sso || !this.$route.query.sig) {
      this.$router.push("/")
      return
    }

    if (!this.$store.getters["auth/authenticated"]) {
      this.$router.push({
        name: "LoginPage",
        query: { sso: this.$route.query.sso, sig: this.$route.query.sig }
      })
      return
    }

    try {
      let response = await Repository.get(
        `admin/discourse/sso?sig=${this.$route.query.sig}&sso=${this.$route.query.sso}`
      )
      window.open(response.data.discourse_url, "_self")
    } catch (error) {
      utils.trackError(error)
      alert("Es ist ein Fehler bei der Anmeldung im Forum aufgetreten.")
    }
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;

  h2 {
    margin-right: 24px;
  }
}
</style>
