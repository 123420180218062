import Repository from "@/repositories/api.js"
const resource = "/super_admin/regions"

export default {
  async getRegions() {
    let response = await Repository.get(resource)
    return response.data.regions
  },
  async get(regionID) {
    let response = await Repository.get(`${resource}/${regionID}`)
    return response.data.region
  },
  async create(regionData) {
    let response = await Repository.post(`${resource}`, { region: regionData })
    return response.data.region
  },
  async update(regionID, regionData) {
    let response = await Repository.patch(`${resource}/${regionID}`, {
      region: regionData
    })
    return response.data
  },
  async destroy(regionID) {
    let response = await Repository.delete(`${resource}/${regionID}`)
    return response.data
  }
}
