<template>
  <div class="login-wrapper">
    <div class="login-box">
      <el-form
        ref="loginForm"
        :model="loginForm"
        :rules="rules"
        @keyup.enter.native="checkForm"
      >
        <div class="logo">
          <img src="../assets/images/logo.svg" width="300" />
        </div>
        <el-form-item label="E-Mail-Adresse" prop="email">
          <el-input id="email" v-model.trim="loginForm.email"></el-input>
        </el-form-item>
        <el-form-item label="Passwort" prop="password">
          <el-input
            id="password"
            v-model="loginForm.password"
            type="password"
            show-password
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            class="login-button"
            :loading="loading"
            @click="checkForm"
          >
            Einloggen
          </el-button>
        </el-form-item>
        <div class="links-container">
          <el-link @click="forgotPassword">Passwort vergessen?</el-link>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as log from "loglevel"
import { mapGetters } from "vuex"
import FormMixin from "@/mixins/form.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import UserRepository from "@/repositories/user_repository.js"
import utils from "@/utils"

export default {
  metaInfo: {
    title: "Login"
  },
  mixins: [FormMixin, ApiErrorHandlerMixin],
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      },
      rules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        password: [
          { required: true, message: "Bitte Passwort angeben", trigger: "blur" }
        ]
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters("auth", ["userData"])
  },
  methods: {
    forgotPassword() {
      this.$router.push({ name: "ForgotPasswordPage" })
    },
    checkForm() {
      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.loading = true
      try {
        let data = await UserRepository.loginUser(
          this.loginForm.email,
          this.loginForm.password
        )
        this.loading = false
        this.$store.commit("auth/login", data)

        utils.addErrorBreadcrumb({
          category: "Auth", // e.g. "UI", "Network", "Navigation", "Console"
          action: "Logged in", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
          metadata: {
            userType: this.$store.getters["auth/userType"],
            userId: this.userData.id
          } // key/value metadata in <string, string> format
        })

        if (this.$route.query.sso && this.$route.query.sig) {
          this.$router.push({
            name: "DiscourseSSOPage",
            query: { sso: this.$route.query.sso, sig: this.$route.query.sig }
          })
          return
        }

        if (
          this.$store.getters["auth/isSuperAdmin"] ||
          this.$store.getters["auth/isRegionUser"]
        ) {
          try {
            await this.$router.push({
              name: "DashboardPage",
              query: { allow_redirect: true }
            })
          } catch {
            log.info("TEMP: Ignoring route redirect error after login")
          }
        } else if (this.$store.getters["auth/isOrganizationUser"]) {
          this.$router.push({ name: "EventsPage" })
        } else {
          this.$router.push({
            name: "SignupPage",
            params: { id: this.userData.region_id }
          })
        }
      } catch (error) {
        this.loading = false
        if (error.response && error.response.status === 422) {
          this.$message({
            showClose: true,
            message: "E-Mail-Adresse und Passwort stimmen nicht überein.",
            type: "error",
            duration: 10000
          })
        } else {
          this.handleApiError(error)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.login-box {
  width: 400px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

.login-button {
  width: 100%;
}

.links-container {
  display: flex;
  justify-content: center;
}

.logo {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
</style>
