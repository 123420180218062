<template>
  <div>
    <page-content page-title="Superadmin 33"> </page-content>
  </div>
</template>

<script>
import PageContent from "@/components/PageContent"

export default {
  components: {
    PageContent
  }
}
</script>
