<template>
  <el-form
    ref="userForm"
    v-loading="loading"
    :model="user"
    :rules="rules"
    label-position="top"
    @keyup.enter.native="checkForm"
  >
    <el-form-item label="Ihre E-Mail-Adresse" prop="email">
      <el-input v-model.trim="user.email" v-focus></el-input>
    </el-form-item>

    <el-form-item
      label="Ihre E-Mail-Adresse wiederholen"
      prop="emailConfirmation"
    >
      <el-input v-model.trim="user.emailConfirmation"></el-input>
    </el-form-item>

    <el-form-item label="Passwort wählen" prop="password">
      <el-input v-model="user.password" type="password"></el-input>
    </el-form-item>

    <div class="buttons-wrapper">
      <div class="links-container">
        <el-link @click="gotoLogin">Ich habe schon ein Konto</el-link>
      </div>
      <el-button
        type="primary"
        :loading="saving"
        class="button-right"
        @click="checkForm"
      >
        Weiter
      </el-button>
    </div>
  </el-form>
</template>

<script>
import FormMixin from "@/mixins/form.js"

export default {
  mixins: [FormMixin],
  props: {
    user: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      rules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        emailConfirmation: [
          {
            required: true,
            message: "Bitte E-Mail-Adresse wiederholen",
            trigger: "blur"
          },
          { validator: this.validateEmails, trigger: "blur" }
        ],
        password: [
          {
            required: true,
            message: "Bitte Passwort angeben",
            trigger: "blur"
          },
          {
            min: 10,
            message: "Das Passwort muss mind. 10 Zeichen haben",
            trigger: "change"
          }
        ]
      }
    }
  },
  created() {},
  methods: {
    gotoLogin() {
      this.$router.push({ name: "LoginPage" })
    },
    checkForm() {
      this.$refs["userForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.$emit("form-submitted", this.user)
    },
    validateEmails(rule, value, callback) {
      if (value !== this.user.email) {
        callback(new Error("Die E-Mail-Adressen stimmen nicht überein"))
      } else {
        callback()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
