<template>
  <el-form
    ref="organizationForm"
    :model="organization"
    :rules="rules"
    label-position="top"
    @keyup.enter.native="checkForm"
  >
    <el-form-item label="Name der Kontaktperson" prop="contact_person_name">
      <el-input v-model="organization.contact_person_name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="E-Mail-Adresse" prop="email">
      <el-input v-model.trim="organization.email"></el-input>
    </el-form-item>

    <el-form-item label="Telefon" prop="phone">
      <el-input v-model="organization.phone"></el-input>
    </el-form-item>

    <el-form-item label="Website" prop="url">
      <el-input
        v-model="organization.url"
        placeholder="https://www.beispiel.de"
      ></el-input>
    </el-form-item>

    <div class="legal">
      <p>
        Ihre eingereichten Veranstaltungen und die damit verbundenen
        übermittelten personenbezogenen Daten werden vom Redaktionsteam vor
        einer Freigabe überprüft. Das Redaktionsteam behält sich aus Gründen der
        Übersichtlichkeit inhaltliche Änderungen und Zuordnungen, sowie die
        Ablehnung nicht passender Angebote, vor.
      </p>

      <div style="margin-bottom: 24px;">
        <div class="checkbox-wrapper">
          <input
            id="data_accepted"
            v-model="dataAccepted"
            type="checkbox"
            class="checkbox-input"
          />
          <label class="checkbox-label" for="data_accepted">
            <span>
              Hiermit willige ich als Veranstaltungsanbieter der Verarbeitung
              der von mir übermittelten personenbezogenen Daten ein.
            </span>
          </label>
        </div>

        <div class="checkbox-wrapper">
          <input
            id="privacy_accepted"
            v-model="privacyAccepted"
            type="checkbox"
            class="checkbox-input"
          />
          <label class="checkbox-label" for="privacy_accepted">
            Die
            <a
              target="_blank"
              :href="'https://' + region.app_host + '/datenschutz'"
            >
              Datenschutzerklärung
            </a>
            habe ich zur Kenntnis genommen.
          </label>
        </div>
      </div>
    </div>

    <div class="buttons-wrapper">
      <el-button @click="goBack">
        Zurück
      </el-button>

      <el-button type="primary" :loading="saving" @click="checkForm">
        Weiter
      </el-button>
    </div>
  </el-form>
</template>

<script>
import FormMixin from "@/mixins/form.js"

export default {
  mixins: [FormMixin],
  props: {
    organization: {
      type: Object,
      required: true
    },
    saving: {
      type: Boolean,
      default: false
    },
    region: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      dataAccepted: false,
      privacyAccepted: false,
      rules: {
        phone: [
          {
            required: true,
            message: "Bitte Telefonnummer angeben",
            trigger: "blur"
          }
        ],
        contact_person_name: [
          {
            required: true,
            message: "Bitte Kontaktperson angeben",
            trigger: "blur"
          }
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Bitte gültige E-Mail-Adresse angeben",
            trigger: "blur"
          }
        ],
        url: [
          {
            type: "url",
            message: "Bitte gültige URL mit https:// oder http:// angeben",
            trigger: "blur"
          }
        ]
      }
    }
  },
  created() {},
  methods: {
    checkForm() {
      this.$refs["organizationForm"].validate(valid => {
        if (valid) {
          this.checkLegal()
        } else {
          this.showFormErrors()
        }
      })
    },
    submitForm() {
      this.$emit("form-submitted", this.organization)
    },
    goBack() {
      this.$emit("form-prev", this.organization)
    },
    checkLegal() {
      if (this.dataAccepted && this.privacyAccepted) {
        this.submitForm()
      } else {
        this.$message({
          message: `Bevor Sie fortfahren können, müssen Sie die Anmeldebedingungen akzeptieren.`,
          type: "error",
          showClose: true
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.legal {
  font-size: 13px;
  line-height: 1.35;

  .checkbox-wrapper {
    display: flex;
    margin-bottom: 16px;
  }

  .checkbox-input {
    margin-top: 2px;
  }

  .checkbox-label {
    margin-left: 10px;
    display: inline-block;
  }
}
</style>
