<template>
  <el-select
    v-model="regionId"
    v-loading="loadingRegions"
    style="min-width: 250px;"
    filterable
    placeholder="Auswählen"
    @change="setRegion"
  >
    <el-option
      v-for="item in regions"
      :key="item.id"
      :label="item.name"
      :value="item.id"
    ></el-option>
  </el-select>
</template>

<script>
import RegionsRepository from "@/repositories/super_admin/regions_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  components: {},
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loadingRegions: false,
      regions: [],
      regionId: null
    }
  },
  computed: {},
  async created() {
    this.loadingRegions = true

    try {
      this.regions = await RegionsRepository.getRegions()
      if (this.$store.state.auth.chosenRegion) {
        this.regionId = this.$store.state.auth.chosenRegion
      } else {
        this.regionId = this.regions[0].id
        this.$store.commit("auth/setRegion", this.regions[0].id)
      }
      this.loadingRegions = false
    } catch (error) {
      this.loadingRegions = false
      this.handleApiError(error, "Fehler Laden Regionen")
    }
  },
  methods: {
    setRegion() {
      this.$store.commit("auth/setRegion", this.regionId)
      this.$emit("region:chosen", this.regionId)
    }
  }
}
</script>
