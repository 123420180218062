<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Übersicht">
      <template #actions>
        <region-chooser
          v-if="$store.getters['auth/isSuperAdmin']"
          @region:chosen="loadCharts"
        ></region-chooser>
      </template>
      <el-row :gutter="24">
        <el-col :xs="24" :xl="12">
          <chart-card
            :chart-options="eventsActivityChartOptions"
            :series="eventsActivitySeries"
            type="line"
            heading="Veranstaltungen"
            :loading="loading"
            height="300"
          ></chart-card>
        </el-col>
        <el-col :xs="24" :xl="12">
          <chart-card
            :chart-options="eventsCategoriesChartOptions"
            :series="eventsCategoriesSeries"
            type="pie"
            heading="Veranstaltungskategorien"
            height="300"
            :loading="loading"
          ></chart-card>
        </el-col>
        <el-col :xs="24" :xl="12">
          <chart-card
            :chart-options="organizationsChartOptions"
            :series="organizationsSeries"
            type="bar"
            heading="Aktivste Veranstalter"
            :loading="loading"
            height="300"
          ></chart-card>
        </el-col>
      </el-row>
    </page-content>
  </div>
</template>

<script>
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"
import RegionChooser from "@/components/RegionChooser"
import PageContent from "@/components/PageContent"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import RegionAnalyticsRepository from "@/repositories/region_analytics_repository.js"
import ChartCard from "@/components/ChartCard"

export default {
  components: {
    PageContent,
    TheBreadcrumbs,
    RegionChooser,
    ChartCard
  },
  mixins: [ApiErrorHandlerMixin],
  data: function() {
    return {
      loading: false,
      eventsActivityChartOptions: {
        title: {
          text: "pro Monat"
        }
      },
      organizationsChartOptions: {
        plotOptions: {
          bar: {
            horizontal: true
          }
        }
      },
      eventsActivitySeries: [],
      eventsCategoriesSeries: [],
      organizationsSeries: [],
      eventsCategoriesLabels: []
    }
  },
  computed: {
    eventsCategoriesChartOptions() {
      return {
        labels: this.eventsCategoriesLabels
      }
    }
  },
  async created() {
    this.loadCharts()
  },
  methods: {
    async loadCharts() {
      this.loading = true

      try {
        let data = await RegionAnalyticsRepository.dashboardAnalytics()
        this.eventsActivitySeries = [
          {
            name: "Erstellt",
            data: data.events_activity.created_at
          },
          {
            name: "Aktualisiert",
            data: data.events_activity.updated_at
          }
        ]
        this.eventsCategoriesSeries = data.events_categories.values
        this.eventsCategoriesLabels = data.events_categories.labels
        this.organizationsSeries = [
          {
            name: "Veranstaltungen",
            data: data.organizations
          }
        ]
        this.loading = false
      } catch (error) {
        this.loading = false
        this.handleApiError(error)
      }
    }
  }
}
</script>
