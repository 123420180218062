<template>
  <div class="login-wrapper">
    <div class="content-wrapper">
      <h1>404 – Seite nicht gefunden</h1>
      <div class="logo">
        <router-link to="/">
          <img src="../assets/images/logo.svg" width="300" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "404 – Seite nicht gefunden"
  }
}
</script>

<style lang="scss" scoped>
.login-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.logo {
  display: flex;
  justify-content: center;
}
</style>
