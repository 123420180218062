<template>
  <component :is="dashboard"></component>
</template>

<script>
import { mapGetters } from "vuex"
import RegionDashboard from "@/components/RegionDashboard"
import SuperAdminDashboard from "@/components/SuperAdminDashboard"

export default {
  metaInfo: {
    title: "Übersicht"
  },
  components: {
    RegionDashboard,
    SuperAdminDashboard
  },
  data() {
    return {
      dashboard: null
    }
  },
  computed: {
    ...mapGetters("auth", ["isSuperAdmin"])
  },
  created() {
    this.dashboard = "RegionDashboard"
    // if (this.isSuperAdmin) {
    //   this.dashboard = "SuperAdminDashboard"
    // } else {
    //   this.dashboard = "RegionDashboard"
    // }
  }
}
</script>
