export default {
  methods: {
    showFormErrors() {
      this.$message({
        message: `Bitte überprüfen Sie Ihre Eingaben`,
        type: "error",
        showClose: true
      })
      this.$nextTick(() => {
        let domRect = document
          .querySelector(".is-error")
          .getBoundingClientRect()
        window.scrollTo(
          domRect.left + document.documentElement.scrollLeft,
          domRect.top + document.documentElement.scrollTop - 60 - 16
        )
      })
    }
  }
}
