import Vue from "vue"
import App from "./App.vue"
import router from "@/router"
import store from "@/store"
import "./plugins/element.js"
import "./assets/scss/main.scss"
import PortalVue from "portal-vue"
import * as log from "loglevel"
import VueMeta from "vue-meta"
import Appsignal from "@appsignal/javascript"
import { errorHandler } from "@appsignal/vue"
import { plugin as networkBreadcrumbPlugin } from "@appsignal/plugin-breadcrumbs-network"
import { plugin } from "@appsignal/plugin-path-decorator"
import VueClipboard from "vue-clipboard2"
import { library, dom } from "@fortawesome/fontawesome-svg-core"
import {
  faUserSecret,
  faQuestionCircle,
  faCog,
  faSignOut,
  faThLarge,
  faCalendarStar,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faList,
  faUsers,
  faExclamationTriangle,
  faBuilding,
  faTags,
  faBooks,
  faPhone,
  faNewspaper,
  faDownload,
  faFile,
  faText,
  faEdit,
  faTrashAlt,
  faPlus,
  faBold,
  faItalic,
  faH2,
  faH3,
  faListUl,
  faListOl,
  faImage,
  faUndo,
  faRedo,
  faLink,
  faThumbsDown,
  faThumbsUp,
  faUserEdit,
  faPlayCircle,
  faPalette,
  faEllipsisH,
  faAnalytics,
  faFileSearch,
  faEye,
  faFolder,
  faBook,
  faFileExport,
  faCopy,
  faInfoSquare,
  faCodeBranch,
  faHammer,
  faCheck,
  faUnlink,
  faFileChartLine,
  faAt,
  faLongArrowRight,
  faLongArrowLeft
} from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import api from "@/repositories/api"

import DefaultLayout from "@/layouts/DefaultLayout.vue"
import SimpleLayout from "@/layouts/SimpleLayout.vue"

const isProduction = process.env.NODE_ENV === "production"

Vue.config.productionTip = false

Vue.component("DefaultLayout", DefaultLayout)
Vue.component("SimpleLayout", SimpleLayout)

log.setLevel(isProduction ? 5 : 0, false)

api.interceptors.request.use(
  config => {
    const u = JSON.parse(localStorage.getItem("familien-app-user")) || null
    if (u && u.access_token) {
      config.headers.common["Authorization"] = `Bearer ${u.access_token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

const user = JSON.parse(localStorage.getItem("familien-app-user")) || null
if (user && user.access_token) {
  log.info("User bereits eingeloggt")
  if (store.getters["auth/isSuperAdmin"]) {
    log.setLevel(0, false)
  }
  if (
    store.getters["auth/isSuperAdmin"] ||
    store.getters["auth/isRegionUser"]
  ) {
    log.info("Display Chatbot…")
    document.body.classList.add("show-chatbot")
  }
} else {
  log.info("User nicht eingeloggt")
}

if (isProduction) {
  window.appsignal = new Appsignal({
    key: "d0e84cf9-eb4c-4cf3-9642-072e198a0aa7",
    namespace: "admin",
    revision: process.env.VUE_APP_VERSION
  })

  window.appsignal.use(plugin({}))
  window.appsignal.use(networkBreadcrumbPlugin({}))

  window.appsignal.addDecorator(span => {
    if (store.getters["auth/authenticated"]) {
      span.setTags({
        user_id: store.getters["auth/userData"].id,
        user_email: store.getters["auth/userData"].email,
        user_type: store.getters["auth/userType"]
      })
    }
    return span
  })

  Vue.config.errorHandler = errorHandler(window.appsignal, Vue)
}

Vue.use(PortalVue)
Vue.use(VueClipboard)

// Register a global custom directive called `v-focus`
Vue.directive("focus", {
  // When the bound element is inserted into the DOM...
  inserted: function(el, binding, vnode) {
    // Focus the element
    vnode.componentInstance.focus()
  }
})

Vue.use(VueMeta)

// config.autoAddCss = false
library.add(
  faUserSecret,
  faQuestionCircle,
  faCog,
  faSignOut,
  faThLarge,
  faCalendarStar,
  faMapMarkerAlt,
  faMapMarkedAlt,
  faList,
  faUsers,
  faExclamationTriangle,
  faBuilding,
  faTags,
  faBooks,
  faPlayCircle,
  faPhone,
  faNewspaper,
  faDownload,
  faFile,
  faText,
  faEdit,
  faTrashAlt,
  faPlus,
  faBold,
  faItalic,
  faH2,
  faH3,
  faListUl,
  faListOl,
  faImage,
  faUndo,
  faRedo,
  faLink,
  faThumbsDown,
  faThumbsUp,
  faUserEdit,
  faPalette,
  faEllipsisH,
  faAnalytics,
  faFileSearch,
  faEye,
  faFolder,
  faBook,
  faFileExport,
  faCopy,
  faInfoSquare,
  faCodeBranch,
  faHammer,
  faCheck,
  faUnlink,
  faFileChartLine,
  faAt,
  faLongArrowRight,
  faLongArrowLeft
)
Vue.component("FontAwesomeIcon", FontAwesomeIcon)
dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

window.Apex.chart = {
  locales: [
    {
      name: "de",
      options: {
        months: [
          "Januar",
          "Februar",
          "März",
          "April",
          "Mai",
          "Juni",
          "Juli",
          "August",
          "September",
          "Oktober",
          "November",
          "Dezember"
        ],
        shortMonths: [
          "Jan",
          "Feb",
          "Mär",
          "Apr",
          "Mai",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Okt",
          "Nov",
          "Dez"
        ],
        days: [
          "Sonntag",
          "Montag",
          "Dienstag",
          "Mittwoch",
          "Donnerstag",
          "Freitag",
          "Samstag"
        ],
        shortDays: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
        toolbar: {
          exportToSVG: "SVG speichern",
          exportToPNG: "PNG speichern",
          menu: "Menü",
          selection: "Auswahl",
          selectionZoom: "Auswahl vergrößern",
          zoomIn: "Vergrößern",
          zoomOut: "Verkleinern",
          pan: "Verschieben",
          reset: "Zoom zurücksetzen"
        }
      }
    }
  ],
  defaultLocale: "de"
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
