<template>
  <div class="breadcrumb-wrapper">
    <el-breadcrumb v-if="breadcrumbItems.length >= 1" separator="/">
      <el-breadcrumb-item
        v-for="item in breadcrumbItems"
        :key="item.path"
        :to="{ path: item.path }"
      >
        {{ item.text }}
      </el-breadcrumb-item>
      <el-breadcrumb-item>{{ currentRoute }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-page-header
      v-else
      title="Zurück"
      :content="currentRoute"
      @back="goBack"
    />
  </div>
</template>

<script>
export default {
  props: {
    customBreadcrumbs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      customRegexPaths: [
        {
          regex: /^\/dashboard$/i,
          text: `Übersicht`
        },
        {
          regex: /^\/analytics$/i,
          text: `Analytics`
        },
        {
          regex: /^\/shared_articles$/i,
          text: `Artikeldatenbank`
        },
        {
          regex: /^\/events\/action_needed$/i,
          text: `Aktion notwendig`
        },
        {
          regex: /^\/events\/((?:[\d+]+?))\/ask_for_edit$/i,
          text: `Um Überarbeitung bitten`
        },
        {
          regex: /^\/events\/((?:[\d+]+?))\/reject$/i,
          text: `Veranstaltung ablehnen`
        },
        {
          regex: /^\/events\/past$/i,
          text: `Vergangene Veranstaltungen`
        },
        {
          regex: /^\/videos\/((?:[\d+]+?))\/new$/i,
          text: `Video erstellen`
        },
        {
          regex: /^\/videos\/((?:[\d+]+?))\/edit$/i,
          text: `Video bearbeiten`
        },
        {
          regex: /^\/guide$/i,
          text: `Wissenswertes`
        },
        {
          regex: /^\/user\/settings$/i,
          text: `Kontoeinstellungen`
        },
        {
          regex: /^\/region\/settings\/general$/i,
          text: `Allgemeine Einstellungen`
        },
        {
          regex: /^\/region\/settings\/text_snippets$/i,
          text: `Einstellungen Textbausteine`
        },
        {
          regex: /^\/region\/settings\/colors$/i,
          text: `Farbeinstellungen`
        },
        {
          regex: /^\/organization$/i,
          text: `Einstellungen`
        },
        {
          regex: /^\/export\/email_addresses$/i,
          text: `Export E-Mail-Adressen`
        },
        {
          regex: /^\/export\/institutions$/i,
          text: `Export Einrichtungen`
        },
        {
          regex: /^\/export\/organizations$/i,
          text: `Export Veranstalter`
        },
        {
          regex: /^\/export\/events$/i,
          text: `Export Veranstaltungen`
        },
        {
          regex: /^\/articles\/((?:[\d+]+?))\/review_changes$/i,
          text: `Versionen des Artikels vergleichen`
        },
        {
          regex: /^\/icons$/i,
          text: `Icons`
        }
      ],
      pathResources: [
        {
          basePath: "super_admin/regions",
          singular: "Region",
          plural: "Regionen"
        },
        {
          basePath: "super_admin/users",
          singular: "Benutzer",
          plural: "Benutzer"
        },
        {
          basePath: "users",
          singular: "Benutzer",
          plural: "Benutzer"
        },
        {
          basePath: "events",
          singular: "Veranstaltung",
          plural: "Veranstaltungen"
        },
        {
          basePath: "events/organizations",
          singular: "Veranstalter",
          plural: "Veranstalter"
        },
        {
          basePath: "events/categories",
          singular: "Veranstaltungskategorie",
          plural: "Veranstaltungskategorien"
        },
        {
          basePath: "events/locations",
          singular: "Veranstaltungsort",
          plural: "Veranstaltungsorte"
        },
        {
          basePath: "guide_items",
          singular: "Element",
          plural: "Elemente"
        },
        {
          basePath: "article_collections",
          singular: "Artikelsammlung",
          plural: "Artikelsammlungen"
        },
        {
          basePath: "institution_collections",
          singular: "Einrichtungssammlung",
          plural: "Einrichtungssammlungen"
        },
        {
          basePath: "articles",
          singular: "Artikel",
          plural: "Artikel"
        },
        {
          basePath: "institutions",
          singular: "Einrichtung",
          plural: "Einrichtungen"
        },
        {
          basePath: "video_playlists",
          singular: "Playlist",
          plural: "Video Playlists"
        },
        {
          basePath: "emergency_numbers",
          singular: "Nummer",
          plural: "Notfallnummern"
        },
        {
          basePath: "news",
          singular: "Neuigkeit",
          plural: "Neuigkeiten"
        },
        {
          basePath: "downloads",
          singular: "Download",
          plural: "Downloads"
        },
        {
          basePath: "downloads/categories",
          singular: "Downloadkategorie",
          plural: "Downloadkategorien"
        },
        {
          basePath: "broken_links",
          singular: "Fehlerhafter Link",
          plural: "Fehlerhafte Links"
        }
      ]
    }
  },
  computed: {
    currentRoute() {
      return this.matchPath(this.$route.path)
    },
    breadcrumbItems() {
      if (this.customBreadcrumbs.length === 0) {
        let items = []
        let that = this
        if (this.$route.meta.breadcrumbs) {
          this.$route.meta.breadcrumbs.forEach(function(c) {
            items.push({
              path: c,
              text: that.matchPath(c)
            })
          })
          return items
        }
        return []
      } else {
        return this.customBreadcrumbs
      }
    },
    regexPaths() {
      let paths = []
      let that = this
      this.pathResources.forEach(function(resource) {
        paths = paths.concat(that.buildPathsForResource(resource))
      })
      return paths.concat(this.customRegexPaths)
    }
  },
  methods: {
    buildPathsForResource(resource) {
      return [
        {
          regex: new RegExp(`^\\/${resource.basePath}$`, "i"),
          text: `${resource.plural}`
        },
        {
          regex: new RegExp(`^\\/${resource.basePath}\\/new$`, "i"),
          text: `${resource.singular} erstellen`
        },
        {
          regex: new RegExp(`^\\/${resource.basePath}\\/((?:[\\d+]+?))$`, "i"),
          text: `${resource.singular} anzeigen`
        },
        {
          regex: new RegExp(
            `^\\/${resource.basePath}\\/((?:[\\d+]+?))\\/edit$`,
            "i"
          ),
          text: `${resource.singular} bearbeiten`
        }
      ]
    },
    matchPath(path) {
      let text = "Fehler: N/A"
      let foundMatch = false
      let i = 0
      while (!foundMatch && i < this.regexPaths.length) {
        if (path.match(this.regexPaths[i].regex)) {
          foundMatch = true
          text = this.regexPaths[i].text
          return `${text}`
        } else {
          i++
        }
      }
      return text
    },
    regexEscape(input) {
      return input.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&")
    },
    goBack() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb-wrapper {
  padding: 25px;
  //border-bottom: solid 1px #e6e6e6;
}
</style>
