import Vue from "vue"
import Router from "vue-router"
import decode from "jwt-decode"

import store from "@/store"
import AppVersionRepository from "@/repositories/app_version_repository.js"
import utils from "@/utils"

import LoginPage from "@/views/LoginPage.vue"
import DiscourseSSOPage from "@/views/DiscourseSSOPage.vue"
import DashboardPage from "@/views/DashboardPage.vue"
import SignupPage from "@/views/SignupPage.vue"
import NotFoundPage from "@/views/NotFoundPage.vue"

Vue.use(Router)

function accessTokenExpired() {
  const token = decode(store.state.auth.user.access_token)
  if (!token.exp) {
    return true
  }

  const date = new Date(0)
  date.setUTCSeconds(token.exp)
  return date < new Date()
}

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/authenticated"]) {
    next()
    return
  }

  if (
    store.getters["auth/isSuperAdmin"] ||
    store.getters["auth/isRegionUser"]
  ) {
    next("/dashboard")
  } else if (store.getters["auth/isOrganizationUser"]) {
    next("/events")
  } else {
    store.commit("auth/logout")
    confirm("Ihre Sitzung ist ungültig. Bitte melden Sie sich erneut an.")
    next("/")
  }
}

const ifSuperAdmin = (to, from, next) => {
  if (!store.getters["auth/isSuperAdmin"]) {
    next("/")
    return
  }
  next()
}

const canManageRegion = (to, from, next) => {
  if (
    !store.getters["auth/isRegionUser"] &&
    !store.getters["auth/isSuperAdmin"]
  ) {
    next("/")
    return
  }
  next()
}

const canSignupAsOrganization = (to, from, next) => {
  if (
    store.getters["auth/isRegionUser"] ||
    store.getters["auth/isSuperAdmin"]
  ) {
    next("/dashboard")
  } else if (store.getters["auth/isOrganizationUser"]) {
    next("/events")
  }
  next()
}

const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "LoginPage",
      meta: { layout: "simple" },
      component: LoginPage,
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/:id/signup",
      name: "SignupPage",
      meta: { layout: "simple" },
      component: SignupPage,
      beforeEnter: canSignupAsOrganization
    },
    {
      path: "/accept_invitation",
      name: "AcceptInvitationPage",
      meta: { layout: "simple" },
      component: () =>
        import(
          /* webpackChunkName: "AcceptInvitationPage" */ "./views/AcceptInvitationPage.vue"
        ),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/forgot_password",
      name: "ForgotPasswordPage",
      meta: { layout: "simple" },
      component: () =>
        import(
          /* webpackChunkName: "ForgotPasswordPage" */ "./views/ForgotPasswordPage.vue"
        ),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/reset_password",
      name: "ResetPasswordPage",
      meta: { layout: "simple" },
      component: () =>
        import(
          /* webpackChunkName: "ResetPasswordPage" */ "./views/ResetPasswordPage.vue"
        ),
      beforeEnter: ifNotAuthenticated
    },
    {
      path: "/discourse_sso",
      name: "DiscourseSSOPage",
      meta: { layout: "simple" },
      component: DiscourseSSOPage
    },
    {
      path: "/user/settings",
      name: "UserSettingsPage",
      component: () =>
        import(
          /* webpackChunkName: "UserSettingsPage" */ "./views/UserSettingsPage.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/dashboard",
      name: "DashboardPage",
      component: DashboardPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/super_admin/regions",
      name: "RegionsPage",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(
          /* webpackChunkName: "RegionsPage" */ "./views/super_admin/RegionsPage.vue"
        ),
      beforeEnter: ifSuperAdmin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/super_admin/regions/new",
      name: "NewRegionPage",
      component: () =>
        import(
          /* webpackChunkName: "NewRegionPage" */ "./views/super_admin/NewRegionPage.vue"
        ),
      beforeEnter: ifSuperAdmin,
      meta: {
        breadcrumbs: ["/super_admin/regions"],
        requiresAuth: true
      }
    },
    {
      path: "/super_admin/regions/:id/edit",
      name: "EditRegionPage",
      component: () =>
        import(
          /* webpackChunkName: "EditRegionPage" */ "./views/super_admin/EditRegionPage.vue"
        ),
      beforeEnter: ifSuperAdmin,
      meta: {
        breadcrumbs: ["/super_admin/regions"],
        requiresAuth: true
      }
    },
    {
      path: "/super_admin/users",
      name: "SuperAdminUsersPage",
      component: () =>
        import(
          /* webpackChunkName: "SuperAdminUsersPage" */ "./views/super_admin/UsersPage.vue"
        ),
      beforeEnter: ifSuperAdmin,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/super_admin/users/new",
      name: "SuperAdminNewUserPage",
      component: () =>
        import(
          /* webpackChunkName: "SuperAdminNewUserPage" */ "./views/super_admin/NewUserPage.vue"
        ),
      beforeEnter: ifSuperAdmin,
      meta: {
        breadcrumbs: ["/super_admin/users"],
        requiresAuth: true
      }
    },
    {
      path: "/super_admin/users/:id/edit",
      name: "SuperAdminEditUserPage",
      component: () =>
        import(
          /* webpackChunkName: "SuperAdminEditUserPage" */ "./views/super_admin/EditUserPage.vue"
        ),
      beforeEnter: ifSuperAdmin,
      meta: {
        breadcrumbs: ["/super_admin/users"],
        requiresAuth: true
      }
    },
    {
      path: "/users",
      name: "UsersPage",
      component: () =>
        import(/* webpackChunkName: "UsersPage" */ "./views/UsersPage.vue"),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/users/new",
      name: "NewUserPage",
      component: () =>
        import(/* webpackChunkName: "NewUserPage" */ "./views/NewUserPage.vue"),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/users"],
        requiresAuth: true
      }
    },
    {
      path: "/users/:id/edit",
      name: "EditUserPage",
      component: () =>
        import(
          /* webpackChunkName: "EditUserPage" */ "./views/EditUserPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/users"],
        requiresAuth: true
      }
    },
    {
      path: "/events",
      name: "EventsPage",
      component: () =>
        import(
          /* webpackChunkName: "EventsPage" */ "./views/events/EventsPage.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/events/past",
      name: "PastEventsPage",
      component: () =>
        import(
          /* webpackChunkName: "PastEventsPage" */ "./views/events/PastEventsPage.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/events/action_needed",
      name: "EventsActionNeededPage",
      component: () =>
        import(
          /* webpackChunkName: "EventsActionNeededPage" */ "./views/events/EventsActionNeededPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events"],
        requiresAuth: true
      }
    },
    {
      path: "/events/new",
      name: "NewEventPage",
      component: () =>
        import(
          /* webpackChunkName: "NewEventPage" */ "./views/events/NewEventPage.vue"
        ),
      meta: {
        breadcrumbs: ["/events"],
        requiresAuth: true
      }
    },
    {
      path: "/events/:id/edit",
      name: "EditEventPage",
      component: () =>
        import(
          /* webpackChunkName: "EditEventPage" */ "./views/events/EditEventPage.vue"
        ),
      meta: {
        breadcrumbs: ["/events"],
        requiresAuth: true
      }
    },
    {
      path: "/events/:id/reject",
      name: "RejectEventPage",
      component: () =>
        import(
          /* webpackChunkName: "RejectEventPage" */ "./views/events/RejectEventPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events"],
        requiresAuth: true
      }
    },
    {
      path: "/events/:id/ask_for_edit",
      name: "AskForEventEditPage",
      component: () =>
        import(
          /* webpackChunkName: "AskForEventEditPage" */ "./views/events/AskForEventEditPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events"],
        requiresAuth: true
      }
    },
    {
      path: "/events/locations",
      name: "EventLocationsPage",
      component: () =>
        import(
          /* webpackChunkName: "EventLocationsPage" */ "./views/events/EventLocationsPage.vue"
        ),
      meta: {
        breadcrumbs: ["/events"],
        requiresAuth: true
      }
    },
    {
      path: "/events/locations/new",
      name: "NewEventLocationPage",
      component: () =>
        import(
          /* webpackChunkName: "NewEventLocationPage" */ "./views/events/NewEventLocationPage.vue"
        ),

      meta: {
        breadcrumbs: ["/events", "/events/locations"],
        requiresAuth: true
      }
    },
    {
      path: "/events/locations/:id/edit",
      name: "EditEventLocationPage",
      component: () =>
        import(
          /* webpackChunkName: "EditEventLocationPage" */ "./views/events/EditEventLocationPage.vue"
        ),

      meta: {
        breadcrumbs: ["/events", "/events/locations"],
        requiresAuth: true
      }
    },
    {
      path: "/events/categories",
      name: "EventCategoriesPage",
      component: () =>
        import(
          /* webpackChunkName: "EventCategoriesPage" */ "./views/events/EventCategoriesPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/events/categories/new",
      name: "NewEventCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "NewEventCategoryPage" */ "./views/events/NewEventCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events/categories"],
        requiresAuth: true
      }
    },
    {
      path: "/events/categories/:id/edit",
      name: "EditEventCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "EditEventCategoryPage" */ "./views/events/EditEventCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events/categories"],
        requiresAuth: true
      }
    },
    {
      path: "/events/organizations",
      name: "SupportOrganizationsPage",
      component: () =>
        import(
          /* webpackChunkName: "SupportOrganizationsPage" */ "./views/events/SupportOrganizationsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/events/organizations/new",
      name: "NewSupportOrganizationPage",
      component: () =>
        import(
          /* webpackChunkName: "NewSupportOrganizationPage" */ "./views/events/NewSupportOrganizationPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events/organizations"],
        requiresAuth: true
      }
    },
    {
      path: "/events/organizations/:id/edit",
      name: "EditSupportOrganizationPage",
      component: () =>
        import(
          /* webpackChunkName: "EditSupportOrganizationPage" */ "./views/events/EditSupportOrganizationPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/events/organizations"],
        requiresAuth: true
      }
    },
    {
      path: "/guide",
      name: "GuidePage",
      component: () =>
        import(
          /* webpackChunkName: "GuidePage" */ "./views/guide/GuidePage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/guide_items/new",
      name: "NewGuideItemPage",
      component: () =>
        import(
          /* webpackChunkName: "NewGuideItemPage" */ "./views/guide/NewGuideItemPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/guide_items/:id",
      name: "ShowGuideItemPage",
      component: () =>
        import(
          /* webpackChunkName: "ShowGuideItemPage" */ "./views/guide/ShowGuideItemPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/guide_items/:id/edit",
      name: "EditGuideItemPage",
      component: () =>
        import(
          /* webpackChunkName: "EditGuideItemPage" */ "./views/guide/EditGuideItemPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/article_collections/:id",
      name: "ShowArticleCollectionPage",
      component: () =>
        import(
          /* webpackChunkName: "ShowArticleCollectionPage" */ "./views/guide/ShowArticleCollectionPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/articles",
      name: "AllArticlesPage",
      component: () =>
        import(
          /* webpackChunkName: "AllArticlesPage" */ "./views/guide/AllArticlesPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/articles/:id/edit",
      name: "EditArticlePage",
      component: () =>
        import(
          /* webpackChunkName: "EditArticlePage" */ "./views/guide/EditArticlePage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/articles/new",
      name: "NewArticlePage",
      component: () =>
        import(
          /* webpackChunkName: "NewArticlePage" */ "./views/guide/NewArticlePage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/articles/:id/review_changes",
      name: "ReviewArticleOriginalChangesPage",
      component: () =>
        import(
          /* webpackChunkName: "ReviewArticleOriginalChangesPage" */ "./views/guide/ReviewArticleOriginalChangesPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/institution_collections/:id",
      name: "ShowInstitutionCollectionPage",
      component: () =>
        import(
          /* webpackChunkName: "ShowInstitutionCollectionPage" */ "./views/guide/ShowInstitutionCollectionPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/institutions",
      name: "AllInstitutionsPage",
      component: () =>
        import(
          /* webpackChunkName: "AllInstitutionsPage" */ "./views/guide/AllInstitutionsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/institutions/:id/edit",
      name: "EditInstitutionPage",
      component: () =>
        import(
          /* webpackChunkName: "EditInstitutionPage" */ "./views/guide/EditInstitutionPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/institutions/new",
      name: "NewInstitutionPage",
      component: () =>
        import(
          /* webpackChunkName: "NewInstitutionPage" */ "./views/guide/NewInstitutionPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/guide"],
        requiresAuth: true
      }
    },
    {
      path: "/video_playlists",
      name: "VideoCategoriesPage",
      component: () =>
        import(
          /* webpackChunkName: "VideoCategoriesPage" */ "./views/videos/VideoCategoriesPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/video_playlists/new",
      name: "NewVideoCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "NewVideoCategoryPage" */ "./views/videos/NewVideoCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/video_playlists"],
        requiresAuth: true
      }
    },
    {
      path: "/video_playlists/:id/edit",
      name: "EditVideoCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "EditVideoCategoryPage" */ "./views/videos/EditVideoCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/video_playlists"],
        requiresAuth: true
      }
    },
    {
      path: "/video_playlists/:id",
      name: "ShowVideoCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "ShowVideoCategoryPage" */ "./views/videos/ShowVideoCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/video_playlists"],
        requiresAuth: true
      }
    },
    {
      path: "/videos/:video_category_id/new",
      name: "NewVideoPage",
      component: () =>
        import(
          /* webpackChunkName: "NewVideoPage" */ "./views/videos/NewVideoPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/video_playlists"],
        requiresAuth: true
      }
    },
    {
      path: "/videos/:id/edit",
      name: "EditVideoPage",
      component: () =>
        import(
          /* webpackChunkName: "EditVideoPage" */ "./views/videos/EditVideoPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/video_playlists"],
        requiresAuth: true
      }
    },
    {
      path: "/emergency_numbers",
      name: "EmergencyNumbersPage",
      component: () =>
        import(
          /* webpackChunkName: "EmergencyNumbersPage" */ "./views/EmergencyNumbersPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/emergency_numbers/new",
      name: "NewEmergencyNumberPage",
      component: () =>
        import(
          /* webpackChunkName: "NewEmergencyNumberPage" */ "./views/NewEmergencyNumberPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/emergency_numbers"],
        requiresAuth: true
      }
    },
    {
      path: "/emergency_numbers/:id/edit",
      name: "EditEmergencyNumberPage",
      component: () =>
        import(
          /* webpackChunkName: "EditEmergencyNumberPage" */ "./views/EditEmergencyNumberPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/emergency_numbers"],
        requiresAuth: true
      }
    },
    {
      path: "/news",
      name: "NewsPage",
      component: () =>
        import(/* webpackChunkName: "NewsPage" */ "./views/NewsPage.vue"),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/news/new",
      name: "NewNewsPage",
      component: () =>
        import(/* webpackChunkName: "NewNewsPage" */ "./views/NewNewsPage.vue"),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/news"],
        requiresAuth: true
      }
    },
    {
      path: "/news/:id/edit",
      name: "EditNewsPage",
      component: () =>
        import(
          /* webpackChunkName: "EditNewsPage" */ "./views/EditNewsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/news"],
        requiresAuth: true
      }
    },
    {
      path: "/downloads/categories",
      name: "DownloadCategoriesPage",
      component: () =>
        import(
          /* webpackChunkName: "DownloadCategoriesPage" */ "./views/DownloadCategoriesPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/downloads/categories/new",
      name: "NewDownloadCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "NewDownloadCategoryPage" */ "./views/NewDownloadCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/downloads/categories"],
        requiresAuth: true
      }
    },
    {
      path: "/downloads/categories/:id/edit",
      name: "EditDownloadCategoryPage",
      component: () =>
        import(
          /* webpackChunkName: "EditDownloadCategoryPage" */ "./views/EditDownloadCategoryPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/downloads/categories"],
        requiresAuth: true
      }
    },
    {
      path: "/downloads",
      name: "DownloadsPage",
      component: () =>
        import(
          /* webpackChunkName: "DownloadsPage" */ "./views/DownloadsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/downloads/new",
      name: "NewDownloadPage",
      component: () =>
        import(
          /* webpackChunkName: "NewDownloadPage" */ "./views/NewDownloadPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/downloads"],
        requiresAuth: true
      }
    },
    {
      path: "/downloads/:id/edit",
      name: "EditDownloadPage",
      component: () =>
        import(
          /* webpackChunkName: "EditDownloadPage" */ "./views/EditDownloadPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        breadcrumbs: ["/downloads"],
        requiresAuth: true
      }
    },
    {
      path: "/region/settings/general",
      name: "GeneralSettingsPage",
      component: () =>
        import(
          /* webpackChunkName: "GeneralSettingsPage" */ "./views/region_settings/GeneralSettingsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/region/settings/text_snippets",
      name: "TextSnippetSettingsPage",
      component: () =>
        import(
          /* webpackChunkName: "TextSnippetSettingsPage" */ "./views/region_settings/TextSnippetSettingsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/region/settings/colors",
      name: "ColorSettingsPage",
      component: () =>
        import(
          /* webpackChunkName: "ColorSettingsPage" */ "./views/region_settings/ColorSettingsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/organization",
      name: "EditOrganizationPage",
      component: () =>
        import(
          /* webpackChunkName: "EditOrganizationPage" */ "./views/EditOrganizationPage.vue"
        ),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/analytics",
      name: "RegionAnalyticsPage",
      component: () =>
        import(
          /* webpackChunkName: "RegionAnalyticsPage" */ "./views/RegionAnalyticsPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/shared_articles",
      name: "SharedArticlesPage",
      component: () =>
        import(
          /* webpackChunkName: "SharedArticlesPage" */ "./views/SharedArticlesPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/export/email_addresses",
      name: "EmailAddressExportPage",
      component: () =>
        import(
          /* webpackChunkName: "EmailAddressExportPage" */ "./views/export/EmailAddressExportPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/export/institutions",
      name: "InstitutionsExportPage",
      component: () =>
        import(
          /* webpackChunkName: "InstitutionsExportPage" */ "./views/export/InstitutionsExportPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/export/organizations",
      name: "OrganizationsExportPage",
      component: () =>
        import(
          /* webpackChunkName: "OrganizationsExportPage" */ "./views/export/OrganizationsExportPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/export/events",
      name: "EventsExportPage",
      component: () =>
        import(
          /* webpackChunkName: "EventsExportPage" */ "./views/export/EventsExportPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/broken_links",
      name: "BrokenLinksPage",
      component: () =>
        import(
          /* webpackChunkName: "BrokenLinksPage" */ "./views/BrokenLinksPage.vue"
        ),
      beforeEnter: canManageRegion,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/icons",
      name: "IconsPage",
      component: () =>
        import(/* webpackChunkName: "IconsPage" */ "./views/IconsPage.vue"),
      meta: {
        requiresAuth: true
      }
    },
    // Catchall Fallback
    {
      path: "*",
      component: NotFoundPage,
      meta: { layout: "simple" }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.getters["auth/authenticated"]) {
      // User seems authenticated, check if token valid
      if (!accessTokenExpired()) {
        // Token is valid
        if (
          from.query.redirect &&
          to.query.allow_redirect === true &&
          to.fullPath != from.query.redirect
        ) {
          // redirect query param is set, so redirect accordingly
          next(from.query.redirect)
        } else {
          // just navigate to the desired page
          next()
        }
      } else {
        // Token is expired, redirect to login
        store.commit("auth/logout")
        confirm("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.")
        next({
          path: "/",
          query: {
            redirect: to.fullPath
          }
        })
      }
    } else {
      // User is not logged in
      next({
        path: "/",
        query: {
          redirect: to.fullPath
        }
      })
    }
  } else {
    // not auth required, just navigate to desired page
    next()
  }
})

router.afterEach((to, from) => {
  utils.addErrorBreadcrumb({
    category: "Navigation", // e.g. "UI", "Network", "Navigation", "Console"
    action: "Navigated to new route", // e.g "The user clicked a button", "HTTP 500 from http://blablabla.com"
    metadata: {
      to: to.path,
      from: from.path
    } // key/value metadata in <string, string> format
  })
})

router.onError(async function(error) {
  if (/loading chunk \d* failed./i.test(error.message)) {
    utils.trackError(error)
    let remoteVersion = await AppVersionRepository.get()
    let localVersion = process.env.VUE_APP_VERSION
    if (remoteVersion != localVersion) {
      window.location.reload()
    }
  }
})

export default router
