import store from "@/store"
import Repository from "@/repositories/api.js"
const resource = "/admin/region_analytics"

export default {
  async dashboardAnalytics() {
    let response = await Repository.get(`${resource}/dashboard_analytics`, {
      params: { region_id: store.state.auth.chosenRegion }
    })
    return response.data
  },
  async visits(start, end) {
    let response = await Repository.get(
      `${resource}/visits?from=${start}&to=${end}`,
      {
        params: { region_id: store.state.auth.chosenRegion }
      }
    )
    return response.data
  }
}
