class CustomError extends Error {
  constructor(error, message) {
    super(message)
    this.name = error
  }
}

export default {
  trackError(errorName, errorMessage) {
    if (process.env.NODE_ENV === "production") {
      const span = window.appsignal.createSpan()
      span.setError(new CustomError(errorName, errorMessage))
      window.appsignal.send(span)
    }
  },
  addErrorBreadcrumb(options) {
    if (process.env.NODE_ENV === "production") {
      window.appsignal.addBreadcrumb(options)
    }
  }
}
