const state = {
  user: JSON.parse(localStorage.getItem("familien-app-user")) || {
    id: null,
    access_token: null,
    email: null,
    roles: [],
    support_organization_id: null,
    region_id: null
  },
  chosenRegion:
    parseInt(localStorage.getItem("familien-app-chosen-region")) || 3
}

// getters
const getters = {
  authenticated: state => !!state.user.access_token,
  userData: state => state.user,
  isOrganizationUser: state => !!state.user.support_organization_id,
  isRegionUser: state =>
    state.user.roles.some(role => role.resource_type === "Region"),
  isSuperAdmin: state => {
    return state.user.roles.some(role => role.name === "super_admin")
  },
  isRegionAdmin: state => {
    return state.user.roles.some(
      role => role.name === "admin" && role.resource_type === "Region"
    )
  },
  userType: (state, getters) => {
    if (getters.isSuperAdmin) {
      return "super_admin"
    } else if (getters.isRegionUser) {
      return "region_user"
    } else {
      return "organization_user"
    }
  },
  canManageRegion: (state, getters) => {
    return getters.isSuperAdmin || getters.isRegionAdmin
  },
  canManageEvents: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "events_manager" && role.resource_type === "Region"
      )
    )
  },
  canManageNumbers: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "numbers_manager" && role.resource_type === "Region"
      )
    )
  },
  canManageDownloads: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "downloads_manager" && role.resource_type === "Region"
      )
    )
  },
  canManageNews: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role => role.name === "news_manager" && role.resource_type === "Region"
      )
    )
  },
  canManageVideos: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "videos_manager" && role.resource_type === "Region"
      )
    )
  },
  canManageInstitutions: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "institutions_manager" &&
          role.resource_type === "Region"
      )
    )
  },
  canManageArticles: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "articles_manager" && role.resource_type === "Region"
      )
    )
  },
  canManageGuide: (state, getters) => {
    return getters.canManageInstitutions || getters.canManageArticles
  },
  canManageAnalytics: (state, getters) => {
    return (
      getters.isSuperAdmin ||
      getters.isRegionAdmin ||
      state.user.roles.some(
        role =>
          role.name === "analytics_manager" && role.resource_type === "Region"
      )
    )
  },
  getRegionId: (state, getters) => {
    if (getters.isRegionUser) {
      return state.user.region_id
    } else {
      return state.chosenRegion
    }
  }
}

// actions
const actions = {}

// mutations
const mutations = {
  login(state, data) {
    state.user = data
    localStorage.setItem("familien-app-user", JSON.stringify(data))
  },
  logout(state) {
    state.user.access_token = null
    state.user.email = null
    localStorage.removeItem("familien-app-user")
    localStorage.removeItem("familien-app-chosen-region")
  },
  setRegion(state, regionID) {
    state.chosenRegion = regionID
    localStorage.setItem("familien-app-chosen-region", regionID)
  },
  setSupportOrganization(state, id) {
    state.user.support_organization_id = id
    localStorage.setItem("familien-app-user", JSON.stringify(state.user))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
