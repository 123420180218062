import Repository from "@/repositories/api.js"
// const resource = "/users"

export default {
  async get() {
    let response = await Repository.get("user")
    return response.data.user
  },
  async update(user) {
    let response = await Repository.patch("user", { user: user })
    return response.data
  },
  async loginUser(email, password) {
    let response = await Repository.post("login", {
      user: {
        email: email,
        password: password
      }
    })
    return response.data
  },
  async acceptInvitation(user) {
    let response = await Repository.post("accept_invitation", {
      user: user
    })
    return response.data
  },
  async requestPasswordInstructions(email) {
    let response = await Repository.post("send_reset_password_instructions", {
      user: {
        email: email
      }
    })
    return response.data
  },
  async resetPassword(token, password) {
    let response = await Repository.post("reset_password", {
      reset_password_token: token,
      password: password
    })
    return response.data
  }
}
