<template>
  <el-form
    ref="organizationForm"
    :model="organization"
    :rules="rules"
    label-position="top"
    @keyup.enter.native="checkForm"
  >
    <el-form-item label="Name der Organisation" prop="name">
      <el-input v-model="organization.name" v-focus></el-input>
    </el-form-item>

    <el-form-item label="Straße und Hausnummer" prop="address">
      <el-input v-model="organization.address"></el-input>
    </el-form-item>

    <el-form-item label="Postleitzahl" prop="zipcode">
      <el-input v-model="organization.zipcode"></el-input>
    </el-form-item>

    <el-form-item label="Ort" prop="city">
      <el-input v-model="organization.city"></el-input>
    </el-form-item>

    <div class="buttons-wrapper">
      <el-button type="primary" class="button-right" @click="checkForm">
        Weiter
      </el-button>
    </div>
  </el-form>
</template>

<script>
import FormMixin from "@/mixins/form.js"

export default {
  mixins: [FormMixin],
  props: {
    organization: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "Bitte Namen eingeben", trigger: "blur" }
        ],
        address: [
          { required: true, message: "Bitte Adresse angeben", trigger: "blur" }
        ],
        zipcode: [
          {
            required: true,
            message: "Bitte PLZ angeben",
            trigger: "blur"
          }
        ],
        city: [
          { required: true, message: "Bitte Ort angeben", trigger: "blur" }
        ]
      }
    }
  },
  created() {},
  methods: {
    checkForm() {
      this.$refs["organizationForm"].validate(valid => {
        if (valid) {
          this.submitForm()
        } else {
          this.showFormErrors()
        }
      })
    },
    async submitForm() {
      this.$emit("form-submitted", this.organization)
    }
  }
}
</script>
