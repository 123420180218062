import Vue from "vue"
import Vuex from "vuex"

import auth from "./modules/auth"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fixedFooterEnabled: false
  },
  mutations: {
    setFixedFooterState(state, enabled) {
      state.fixedFooterEnabled = enabled
    }
  },
  actions: {},
  modules: {
    auth
  },
  strict: process.env.NODE_ENV !== "production"
})
