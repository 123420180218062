import * as log from "loglevel"
import store from "@/store"
import utils from "@/utils"

export default {
  methods: {
    handleApiError(error, errorTitle = "Fehler") {
      log.info(error)

      let showConfirmButton = true
      let errorName = "Unknown Error"
      let redirectToLogin = false
      let errorMessage =
        "Ein unbekannter Fehler ist aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut."

      if (!error.response) {
        errorMessage =
          "Es konnte keine Verbindung mit dem Server hergestellt werden. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut."
        window.appsignal.sendError(error)
      } else {
        switch (error.response.status) {
          case 401:
            redirectToLogin = true
            errorName = "401"
            break
          case 403:
            errorName = "403"
            errorMessage =
              "Fehlercode 403 – Sie sind nicht berechtigt diesen Inhalt anzufragen oder zu bearbeiten."
            break
          case 404:
            errorName = "404"
            errorMessage =
              "Fehlercode 404 – Der angeforderte Inhalt wurde nicht gefunden. Eventuell wurde er entfernt oder verschoben."
            break
          case 422:
            showConfirmButton = false
            errorName = "422"
            errorMessage =
              "Der Server hat Ihre Änderungen bzw. Eingaben abgelehnt. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut."
            if (
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              errorMessage = `Ihre Eingaben enthalten folgende Fehler:<br><strong>${error.response.data.errors.join(
                "; "
              )}</strong>`
            }
            break
          case 423:
            showConfirmButton = false
            errorName = "423"
            errorMessage = "Eintrag konnte nicht gelöscht werden."
            errorTitle = errorMessage
            if (
              error.response.data.errors &&
              error.response.data.errors.length > 0
            ) {
              errorMessage = `Grund:<br><strong>${error.response.data.errors.join(
                "; "
              )}</strong>`
            }
            break
          case 500:
            errorName = "500"
            errorMessage =
              "Es ist ein Serverfehler beim Verarbeiteten Ihrer Anfrage aufgetreten. Bitte setzen Sie sich mit dem Administrator in Verbindung."
            break
          case 502:
            errorName = "502"
            errorMessage = "Fehlercode 502 – Bad Gateway"
            break
          case 503:
            errorName = "503"
            errorMessage = "Fehlercode 503 – Dienst ist nicht erreichbar"
            break
        }
      }

      if (error.response && error.response.status === 422) {
        utils.trackError(`ApiError: ${errorName}`, errorMessage)
      } else {
        utils.trackError(`ApiError: ${errorName}`, error.message)
      }

      if (redirectToLogin) {
        store.commit("auth/logout")
        confirm("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.")
        this.$router.push({ name: "LoginPage" })
        return
      }

      this.$confirm(errorMessage, errorTitle, {
        dangerouslyUseHTMLString: true,
        showConfirmButton: showConfirmButton,
        confirmButtonText: "Seite neu laden",
        cancelButtonText: "Schließen",
        type: "error"
      })
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          //
        })
    }
  }
}
