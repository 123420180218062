<template>
  <div id="layout-wrapper">
    <slot></slot>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
#layout-wrapper {
  min-height: 100vh;
}
</style>
